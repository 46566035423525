import React, { useState } from 'react';
import { Field } from 'react-final-form';
// import { nonEmptyArray } from '../../util/validators';
import css from './FieldMultiSelect.module.css';
import { ValidationError } from '../../components';
const FieldMultiSelect = props => {
  const [selectedOptions, setSelectedOptions] = useState();
  const [isSelected, setIsSelected] = useState(false);
  // REACT-SELECT DOESNT PLAY WELL WITH SSR
  // IF NO WINDOW DONT RENDER
  if (typeof window === 'undefined') {
    return null;
  }
  // CONDITIONALLY IMPORT SELECT MODULES
  const { default: Select, components } = require('react-select'); // eslint-disable-line global-require

  const {
    validate,
    name,
    options,
    label,
    className,
    placeholder,
    meta,
    isMulti,
    isRequired,
    disabled,
    defaultValue,
    ...rest
  } = props;

  const status =
    isSelected && isRequired && !selectedOptions
      ? css.error
      : selectedOptions && selectedOptions.value !== ''
      ? css.success
      : css.attention;
  // const Option = props => (
  //   <div>
  //     <components.Option {...props}>
  //       <label className={css.multiselectValueLabel}>{props.label}</label>
  //     </components.Option>
  //   </div>
  // );
  // const MultiValue = props => (
  //   <components.MultiValue {...props}>
  //     <span className={css.multiSelectLabel}>{props.data.label}</span>
  //   </components.MultiValue>
  // );
  const InputOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
  }) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);

    // styles
    let bg = 'transparent';
    if (isFocused) bg = '#eee';
    if (isActive) bg = '#B2D4FF';

    const style = {
      alignItems: 'center',
      backgroundColor: bg,
      color: 'inherit',
      display: 'flex ',
    };

    // prop assignment
    const props = {
      ...innerProps,
      onMouseDown,
      onMouseUp,
      onMouseLeave,
      style,
    };

    return (
      <components.Option
        {...rest}
        isDisabled={isDisabled}
        isFocused={isFocused}
        isSelected={isSelected}
        getStyles={getStyles}
        innerProps={props}
      >
        <div className={css.inputWrapper}>
          <input type="checkbox" checked={isSelected} />
          <label style={{ fontWeight: isSelected ? 500 : 400 }}>
            {children}
          </label>
        </div>
      </components.Option>
    );
  };

  const renderSelect = typeof window !== 'undefined';
  return options && renderSelect ? (
    <Field name={name} validate={validate}>
      {props => {
        const { input, meta } = props;
        // PULLING INPUT ONCHANGE OUT OF OBJECT SO THAT WE CAN IMPLEMENT OUT OWN
        const { onChange, ...rest } = input;
        {
          /* const { invalid, touched, error } = meta; */
        }
        {
          /* const hasError = touched && invalid && error; */
        }
        return (
          <div className={className}>
            {label ? <label className={css.gap}>{label}</label> : null}
            <div className={status}>
              <Select
                closeMenuOnSelect={!isMulti}
                hideSelectedOptions={false}
                isMulti={isMulti}
                // components={[Option, MultiValue]}
                components={{
                  Option: InputOption,
                }}
                defaultValue={
                  defaultValue && Object.keys(defaultValue).length
                    ? defaultValue
                    : undefined
                }
                options={options}
                isSearchable={true}
                className={css.multiSelectBox}
                isDisabled={disabled}
                placeholder={placeholder}
                selected={selectedOptions}
                getOptionValue={value => {
                  setIsSelected(true);
                  return value.key || value.label;
                }}
                {...rest}
                onBlur={() => input.onBlur(input.value)}
                onChange={item => {
                  setSelectedOptions(item);
                  input.onChange(item);
                }}
                theme={theme => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    text: 'orangered',
                    primary25: '#ddd',
                    primary: '#2e3599',
                  },
                })}
              />
              <ValidationError fieldMeta={meta} />
            </div>
          </div>
        );
      }}
    </Field>
  ) : null;
};
export default FieldMultiSelect;
