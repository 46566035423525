import { storableError } from '../util/errors';
import * as log from '../util/log';
import { createPayee } from '../util/api';

const TIPALTI_PAYER_NAME = process.env.REACT_APP_TIPALTI_PAYER_NAME;

// ================ Action types ================ //

export const TIPALTI_ACCOUNT_CREATE_REQUEST =
  'app/tipalti/TIPALTI_ACCOUNT_CREATE_REQUEST';
export const TIPALTI_ACCOUNT_CREATE_SUCCESS =
  'app/tipalti/TIPALTI_ACCOUNT_CREATE_SUCCESS';
export const TIPALTI_ACCOUNT_CREATE_ERROR =
  'app/tipalti/TIPALTI_ACCOUNT_CREATE_ERROR';

// ================ Reducer ================ //

const initialState = {
  createTipaltiAccountInProgress: false,
  createTipaltiAccountError: null,
  tipaltiAccount: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case TIPALTI_ACCOUNT_CREATE_REQUEST:
      return {
        ...state,
        createTipaltiAccountError: null,
        createTipaltiAccountInProgress: true,
      };
    case TIPALTI_ACCOUNT_CREATE_SUCCESS:
      return {
        ...state,
        createTipaltiAccountInProgress: false,
        tipaltiAccount: payload,
      };
    case TIPALTI_ACCOUNT_CREATE_ERROR:
      console.error(payload);
      return {
        ...state,
        createTipaltiAccountError: payload,
        createTipaltiAccountInProgress: false,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const tipaltiAccountCreateRequest = () => ({
  type: TIPALTI_ACCOUNT_CREATE_REQUEST,
});

export const tipaltiAccountCreateSuccess = payload => ({
  type: TIPALTI_ACCOUNT_CREATE_SUCCESS,
  payload,
});

export const tipaltiAccountCreateError = e => ({
  type: TIPALTI_ACCOUNT_CREATE_ERROR,
  payload: e,
  error: true,
});

// ================ Thunks ================ //

export const createTipaltiAccount = params => dispatch => {
  dispatch(tipaltiAccountCreateRequest());

  const { flexUserId, first, last, currency, company } = params;

  const payload = {
    payee: {
      first,
      last,
      idap: flexUserId,
      payer: TIPALTI_PAYER_NAME,
    },
  };

  if (currency) {
    payload.payee.payeeErpCurrency = currency;
  }
  if (company) {
    payload.payee.company = company;
  }

  return createPayee(payload)
    .then(response => {
      dispatch(tipaltiAccountCreateSuccess(response.data));
    })
    .catch(err => {
      const e = storableError(err);
      dispatch(tipaltiAccountCreateError(e));
      log.error(err, 'create-tipalti-account-failed');
      throw e;
    });
};
