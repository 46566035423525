import React from 'react';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { Button, Form, FieldRadioButton } from '../../components';

import css from './TipaltiAccountForm.module.css';

const TipaltiAccountFormComponent = props => {
  return (
    <FinalForm
      {...props}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          disabled,
          handleSubmit,
          inProgress,
          ready,
          submitButtonText,
          intl,
          pristine,
          invalid,
        } = fieldRenderProps;

        const classes = classNames(rootClassName || css.root, className, {
          [css.disabled]: disabled,
        });

        const showAsRequired = pristine;
        const submitDisabled = pristine || invalid || disabled;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <div className={css.sectionContainer}>
              <div className={css.radioButtonRow}>
                <FieldRadioButton
                  id="individual"
                  name="accountType"
                  label={intl.formatMessage({
                    id: 'StripeConnectAccountForm.individualAccount',
                  })}
                  value="individual"
                  showAsRequired={showAsRequired}
                />
                <FieldRadioButton
                  id="company"
                  name="accountType"
                  label={intl.formatMessage({
                    id: 'StripeConnectAccountForm.companyAccount',
                  })}
                  value="company"
                  showAsRequired={showAsRequired}
                />
              </div>
              <Button
                type="submit"
                inProgress={inProgress}
                disabled={submitDisabled}
                ready={ready}
              >
                {submitButtonText || (
                  <FormattedMessage id="StripeConnectAccountForm.submitButtonText" />
                )}
              </Button>
            </div>
          </Form>
        );
      }}
    />
  );
};

const TipaltiAccountForm = compose(injectIntl)(TipaltiAccountFormComponent);

export default TipaltiAccountForm;
