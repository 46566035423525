import React, { useEffect, useState } from 'react';
import { createSlug } from '../../util/urlHelpers';
import { FormattedMessage } from '../../util/reactIntl';

// import { Modal, Button, NamedLink, IconCollection, AuthenticationForm } from '../../components';

import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';

import css from './AlogoliaSearchCard.module.css';
import IconCollection from '../IconCollection/IconCollection';
import { AuthenticationForms } from '../../containers/AuthenticationPage/AuthenticationPage';
import NamedLink from '../NamedLink/NamedLink';
import Button, {
  InlineTextButton,
  SecondaryButtonInline,
} from '../Button/Button';
import Modal from '../Modal/Modal';
import { useDispatch } from 'react-redux';
import { fetchCurrentUser } from '../../ducks/user.duck';
import {
  checkCountLimit,
  checkPriceLimit,
  getLabel,
} from '../../util/dataExtractor';
import {
  Disciplines,
  IdealTimeframe,
  RequiredPros,
  listingColors,
  listingConditions,
} from '../../config/configListing';
import { listingBrands } from '../../config/configBrand';
import { listingFieldTypes } from '../../config/configTypes';
// import Overlay from '../../containers/ManageListingsPage/ManageListingCard/Overlay';
import { fetchAuthorData } from '../../containers/AlgoliaSearchPage/AlgoliaSearchPage.duck';
import { COMPANY, PROFESSIONAL } from '../../util/types';
import NoImageIcon from '../ResponsiveImage/NoImageIcon';
import { AvatarLarge } from '../Avatar/Avatar';
import ResponsiveImage from '../ResponsiveImage/ResponsiveImage';
import defaultProfileImg from '../../assets/images/defaultProfile.png';
const AlogoliaSearchCard = props => {
  const {
    hit,
    currentUser,
    updateInProgress,
    onUpdateProfile,
    handleAddToCart,
    roleName,
  } = props;

  const [showFullDescription, setShowFullDescription] = useState(false);

  const {
    title,
    portfolioImages,
    objectID,
    description,
    idealTimeframe,
    specifyDiscipline,
    requiredPros,
    budgetRange,
    proSoftwareTools,
    proSoftwareToolsSubCat,
    userRole,
    cloudinaryUrls,
    disciplines,
    spokenLanguages,
    professionalLocation,
    authorData: user,
    professionalProfileUrl,
  } = hit || {};
  const splitTitle = title.split(' '); // Split the title into parts based on space
  const firstName = splitTitle[0]; // "atal"
  const lastName = splitTitle[1]; // "pro2"

  const lastNameFirstLetter = lastName ? lastName.charAt(0) : '';
  const index = professionalLocation?.address?.indexOf(',');

  const firstlocationshow = professionalLocation?.address?.substring(0, index);
  const secondPart = professionalLocation?.address?.substring(index + 1).trim();
  // const isOwnListing = !!currentUser?.id && user?.userId === currentUser?.id?.uuid;

  const isShowComListings =
    roleName === process.env.REACT_APP_ALGOLIA_COMPANY_LISTING_INDEX;
  const isShowProListings =
    roleName === process.env.REACT_APP_ALGOLIA_PROFESSIONAL_LISTING_INDEX;
  const userId = user?.userId;

  const category = getLabel(RequiredPros, requiredPros, 'pro');

  return (
    <div className={css.root}>
      {/*       
      <NamedLink
        name={isShowComListings ? "ListingPage" : "ProfessionalProfilePage"}
        params={isShowComListings ? { id: objectID, slug: createSlug(title) } : { id: userId }}
        className={css.link}
        isSearchPage={true}
      > */}
      <div className={css.jobCard}>
        {userRole === COMPANY ? (
          <NamedLink
            name={isShowComListings ? 'ListingPage' : 'ProfessionalProfilePage'}
            params={
              isShowComListings
                ? { id: objectID, slug: createSlug(title) }
                : { id: userId }
            }
            className={css.link}
            isSearchPage={true}
          >
            <div>
              {cloudinaryUrls?.length > 0 ? (
                <div className={css.cardImage}>
                  <ResponsiveImage
                    url={cloudinaryUrls[0]?.secure_url}
                    alt="listing-img"
                  />
                </div>
              ) : category && category.url ? (
                <div className={css.cardImage}>
                  <ResponsiveImage url={category.url} alt="listing-img" />
                </div>
              ) : (
                <div className={css.cardImage}>
                  <IconCollection icon={'NoImage'} />{' '}
                </div>
              )}
            </div>{' '}
          </NamedLink>
        ) : (
          <div className={css.professionalProfileInfo}>
            <div className={css.image}>
              {professionalProfileUrl ? (
                <div className={css.cardImage}>
                  <ResponsiveImage
                    url={professionalProfileUrl}
                    alt="listing-img"
                  />
                </div>
              ) : (
                <div className={css.cardImage}>
                  {/* <NoImageIcon className={css.noImageIcon} /> */}
                  <ResponsiveImage url={defaultProfileImg} />
                </div>
              )}
            </div>
            <div>
              <p className={css.title}>
                {firstName} {lastNameFirstLetter}
              </p>
              <h6 className={css.professionText}>
                {spokenLanguages && spokenLanguages[0]?.label} |{' '}
                {firstlocationshow}{' '}
              </h6>
              <div className={css.starIcons}>
                <IconCollection name="STAR_ICON" />
                <IconCollection name="STAR_ICON" />
                <IconCollection name="STAR_ICON" />
                <IconCollection name="STAR_ICON" />
                <IconCollection name="STAR_ICON" />
              </div>
            </div>
          </div>
        )}
        {userRole === COMPANY ? (
          <div className={css.categoryTitle}>
            <span>{requiredPros && category.label}</span>
          </div>
        ) : null}
        <div
          className={
            userRole === COMPANY ? css.cardContents : css.professionalContents
          }
        >
          <div>
            <NamedLink
              name={
                isShowComListings ? 'ListingPage' : 'ProfessionalProfilePage'
              }
              params={
                isShowComListings
                  ? { id: objectID, slug: createSlug(title) }
                  : { id: userId }
              }
              className={css.link}
              isSearchPage={true}
            >
              {userRole === COMPANY ? (
                <p className={css.title}>{title}</p>
              ) : null}
            </NamedLink>
            <p
              onClick={() => setShowFullDescription(!showFullDescription)}
              className={css.description}
            >
              {showFullDescription ? (
                <span>{description}</span>
              ) : (
                <span>{description.slice(0, 95)}</span>
              )}
              {description?.length > 95 && (
                <span
                  className={css.readStatus}
                  onClick={() => setShowFullDescription(!showFullDescription)}
                >
                  {showFullDescription ? '...READ LESS' : '...READ MORE'}
                </span>
              )}
            </p>
          </div>
          <div className={css.footerData}>
            <div className={css.skillContents}>
              <div className={css.skillContent}>
                <span>
                  {specifyDiscipline &&
                    getLabel(Disciplines, specifyDiscipline)}
                </span>
              </div>
              <div className={css.skillContent}>
                <span>{requiredPros && category.label}</span>
              </div>
            </div>
            {userRole === PROFESSIONAL && (
              <div className={css.category}>
                {disciplines?.slice(0, 2).map(tool => {
                  return <span key={tool.value}>{tool.label}</span>;
                })}
                {proSoftwareTools?.slice(0, 2)?.map(tool => {
                  return <span key={tool.value}>{tool.label}</span>;
                })}
                {/* {userRole === PROFESSIONAL &&
                  proSoftwareToolsSubCat?.slice(0, 2)?.map(tool => {
                    return (
                      <span key={tool.value}>{tool.label}</span>
                    )
                  })} */}
              </div>
            )}

            <NamedLink
              name={
                isShowComListings ? 'ListingPage' : 'ProfessionalProfilePage'
              }
              params={
                isShowComListings
                  ? { id: objectID, slug: createSlug(title) }
                  : { id: userId }
              }
              className={css.link}
              isSearchPage={true}
            >
              {userRole !== PROFESSIONAL && (
                <div className={css.dividerLine}></div>
              )}
              <div className={css.cardFooter}>
                {userRole === PROFESSIONAL ? (
                  <div className={css.buttonWrapper}>
                    <Button className={css.viewBtn}>VIEW PORTFOLIO</Button>
                  </div>
                ) : (
                  <>
                    <div className={css.priceWrapper}>
                      {budgetRange && getLabel(IdealTimeframe, budgetRange)}
                    </div>
                    <span> | </span>
                    {idealTimeframe && (
                      <div className={css.linkbuyNow}>
                        {getLabel(IdealTimeframe, idealTimeframe)} Timeline
                      </div>
                    )}
                  </>
                )}
              </div>
            </NamedLink>
          </div>
        </div>
      </div>
      {/* </NamedLink> */}
    </div>
  );
};

export default AlogoliaSearchCard;
